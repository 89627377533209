.recharge > .container-fluid {
    padding: 0;
}
.recharge {
    background-color: var(--color9);
}
.retreatcontent {
    padding: 35px 82px;
}
.retreatcontent h2 {
    font-size: 30px;
    margin: 0 0 15px;
}
.retreatcontent ul {
    margin: 0;
}
.retreatcontent ul li {
    font-size: 17px;
    margin: 0 0 6px;
}
.retreatcontent p {
    margin: 12px 0 0;
    font-size: 17px;
    font-weight: 600;
}
.retreatcontent p a {
    color: var(--color4);
    transition: all 0.5s ease;
}
.retreatcontent p a:hover {
    color: var(--color1);
}
.refocus {
    background-color: var(--color5);
}
.invest {
    background-color: var(--color6);
}