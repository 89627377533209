.celebratecotnent, .givbackcontent {
    padding: 35px 110px;
}
.celebrate {
    background-color: var(--color10);
    margin: 0 0 50px;
}
.celebratecotnent h2, .givbackcontent h2 {
    font-size: 40px;
    margin: 0 0 15px;
    color: var(--color1);
}
.celebratecotnent p, .givbackcontent p {
    margin: 0 0 15px;
    color: var(--color1);
}
.celebratecotnent .celebratebtns, .givbackcontent .givbackbtn {
    margin: 35px 0 0;
}
.celebratecotnent .celebratebtns a, .givbackcontent .givbackbtn a {
    background-color: var(--color2);
    color: var(--color1);
    transition: all 0.5s ease;
    padding: 12px 45px 15px;
    font-size: 18px;
}
.celebratecotnent .celebratebtns a:hover, .givbackcontent .givbackbtn a:hover {
    color: var(--color2);
    background-color: var(--color1);
}
.dancing {
    margin: 0 0 50px;
}
.dancinggrid .dancingiconare svg {
    height: 50px;
    width: 50px;
}
.dancinggrid .dancingiconare {
    margin: 0 0 15px;
}
.dancinggrid .danceingridcontent h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 10px;
}
.dancinggrid {
    text-align: center;
    padding: 50px 15px 100px;
    min-height: 245px;
    position: relative;
}
.danceingone {
    background-color: var(--color4);
}
.dancinggrid .dancegrbtn {
    position: absolute;
    left: 10px;
    bottom: 10px;
    right: 10px;
}
.dancinggrid .dancegrbtn a {
    display: block;
    background-color: var(--color1);
    color: var(--color2);
    font-size: 18px;
    padding: 12px 0;
    transition: all 0.5s ease;
}
.dancinggrid .dancegrbtn a:hover {
    background-color: var(--color4);
}
.danceingtwo {
    background-color: var(--color5);
}
.danceingthree {
    background-color: var(--color6);
}
.danceingone .dancingiconare, .danceingone .danceingridcontent h2, .danceingone .danceingridcontent p {
    color: var(--color2);
}
.dancinggrid.danceingone .dancegrbtn a:hover {
    background-color: var(--color6);
    color: var(--color1);
}
.givback {
    background-color: var(--color6);
}
.givbacktwo {
    background-color: var(--color5) !important;
}
.givbacktwo .givbackimgae img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.givbacktwo .givbackcontent {
    padding: 70px 110px;
}
@media screen and (max-width: 1366px) {
    .celebrate .celebrateimage img {
        height: 490px;
        object-fit: cover;
    }
    .dancinggrid {
        min-height: 350px;
    }
    .givback .givbackimgae img {
        height: 420px;
        object-fit: cover;
    }
    .givbacktwo .givbackimgae img {
        height: auto;
    }
    .celebratecotnent h2, .givbackcontent h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 1100px) {
    .celebratecotnent h2, .givbackcontent h2 {
        font-size: 30px;
    }
    .celebratecotnent, .givbackcontent, .givbacktwo .givbackcontent {
        padding: 25px 30px;
    }
    .dancinggrid {
        padding: 28px 15px 71px;
        min-height: 320px;
    }
}
@media screen and (max-width: 800px) {
    .celebratecotnent h2, .givbackcontent h2 {
        font-size: 25px;
    }
    .celebratecotnent p, .givbackcontent p {
        font-size: 14px;
    }
    .celebratecotnent .celebratebtns a, .givbackcontent .givbackbtn a {
        padding: 8px 22px 11px;
        font-size: 16px;
    }
    .givback .givbackimgae img {
        height: 375px;
    }
    .dancinggrid .danceingridcontent h2 {
        font-size: 17px;
    }
    .dancinggrid {
        padding: 15px 15px 71px;
        min-height: 290px;
    }
    .dancinggrid .dancingiconare svg {
        height: 40px;
        width: 40px;
    }
    .danceingridcontent p {
        font-size: 15px;
    }
    .dancinggrid .dancegrbtn a {
        font-size: 15px;
    }
    .celebrate .celebrateimage img {
        height: 457px;
    }
    .givbacktwo .givbackimgae img {
        height: 300px;
    }    
}
@media screen and (max-width: 767px) {
    .celebratecoloumrevert > .row > .col-lg-6:last-child {
        order: -1 !important;
    }
    .celebrate, .givback {
        padding: 15px;
    }
    .celebratecotnent, .givbackcontent, .givbacktwo .givbackcontent {
        padding: 8px 0px 18px;
    }
    .celebratecotnent .celebratebtns, .givbackcontent .givbackbtn {
        margin: 20px 0 0;
    }
    .celebrate {
        margin: 0 0 20px;
    }
    .dancinggrid {
        min-height: 230px;
    }
    .dancing {
        margin: 0px 0 20px;
    }
    .celebrate .celebrateimage img {
        height: 260px;
    }
    .givbacktwo > .row > .col-lg-6:last-child {
        order: -1 !important;
    }
    .givbacktwo .givbackimgae img {
        height: auto;
    }
}