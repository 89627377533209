.ourclients {
    background-color: var(--color9);
    padding: 50px 0 70px;
}
.clientsslider .slick-slide {
    padding: 0 10px;
}
.clientsslider .slick-list {
    margin: 0;
}
.clientimgbox .clientimgs img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    background-color: var(--color2);
    padding: 0 15px;
    border-radius: 5px;
}
.clientsslider {
    margin: 20px 0 0;
}
.clientheaidng h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 15px;
    line-height: 1.5;
}
.clientimgbox {
    margin: 0 0 30px;
}
.magic-dots.slick-dots li.slick-active button:before {
    color: var(--color4) !important;
}
@media screen and (max-width: 1366px) {
    .clientheaidng h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 1100px) {
    .clientheaidng h2 {
        font-size: 25px;
    }
}
@media screen and (max-width: 800px) {
    .ourclients {
        padding: 40px 0 60px;
    }    
}