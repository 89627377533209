.findusbox {
    text-align: center;
    background-color: var(--color10);
    border: 2px solid var(--color4);
    padding: 30px;
    border-radius: 18px;
}
.findus {
    padding: 0px 0 80px;
}
.findusheiang h2 {
    margin: 0 0 15px;
    font-size: 27px;
}
.findus .col-lg-6:last-child .findusbox {
    background-color: var(--color13);
}
.findus .col-lg-6:last-child .findusbox h2, .findus .col-lg-6:last-child .findusbox p {
    color: var(--color2);
}
.finduscontent p {
    margin: 0 0 25px;
    font-size: 18px;
    line-height: 1.5;
}
.findusbtns a {
    background-color: var(--color4);
    color: var(--color2);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 30px 13px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.findusbtns a:hover {
    background-color: var(--color1);
}
@media screen and (max-width: 1100px) {
    .findusbox {
        min-height: 240px;
    }
}
@media screen and (max-width: 800px) {
    .findusheiang h2 {
        font-size: 22px;
    }
    .findusbtns a {
        font-size: 13px;
    }
    .findusbox {
        min-height: 262px;
    }
}
@media screen and (max-width: 600px) {
    .findusbox {
        min-height: auto;
        margin: 0 0 30px;
    }
    .findus {
        padding: 0px 0 60px;
    }
}