.errorpage {
    height: calc(100vh - 150px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorcontent {
    text-align: center;
}
.errorcontent h2 {
    color: var(--color2);
    margin: 0;
    font-size: 18ch;
    font-weight: 900;
    line-height: 1.5;
}
.errorcontent h3 {
    color: var(--color2);
    margin: 0;
    font-size: 6ch;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
}
.errorcontent p {
    color: var(--color2);
    font-size: 20px;
}
.errorcontent .errorbtns a {
    background-color: var(--color2);
    padding: 9px 20px 11px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--color1);
    transition: all 0.5s ease;
}
.errorcontent .errorbtns {
    margin: 50px 0 0;
}
.errorcontent .errorbtns a:hover {
    background-color: var(--color4);
    color: var(--color2);
}