.joinus > .container-fluid {
    padding: 0;
}
.joinus {
    background-color: var(--color7);
}
.joinuscontent {
    padding: 35px 110px;
}
.joinuscontent h2 {
    font-size: 40px;
    margin: 0 0 15px;
    color: var(--color2);
}
.joinuscontent p {
    margin: 0 0 15px;
    color: var(--color2);
}
.joinuscontent .joinusbtn a {
    background-color: var(--color4);
    color: var(--color2);
    font-weight: 600;
    padding: 10px 15px 13px;
    transition: all 0.5s ease;
}
.joinuscontent .joinusbtn a:hover {
    background-color: var(--color2);
    color: var(--color4);
}
.joinuscontent .joinusbtn {
    margin: 35px 0 0;
}