.teambuilding {
    background-color: var(--color9);
    padding: 50px 0;
}
.teambuildinghead {
    text-align: center;
    margin: 0 0 25px;
}
.teambuildinghead h2 {
    margin: 0;
    line-height: 1.5;
    font-size: 30px;
    font-weight: 700;
}
.teambuildinggrid {
    background-color: var(--color2);
    border-radius: 5px;
    overflow: hidden;
    min-height: 480px;
    transition: all 0.5s ease;
}
.teambuildcontent {
    padding: 15px;
}
.teambuildimg img {
    height: 300px;
    object-fit: cover;
    transition: all 0.5s ease;
}
.teambuildimg {
    overflow: hidden;
}
.teambuildcontent h3 {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 600;
}
.teambuildcontent p {
    margin: 0;
    font-size: 17px;
    line-height: 1.5;
}
.teambuildinggrid:hover .teambuildimg img {
    transform: rotate(2deg) scale(1.15);
}
.teambuildinggrid:hover {
    box-shadow: 0 10px 25px #3c485826;
    transform: translateY(-5px);
}
.teambuildvideo .getsocialbox {
    margin: 0;
}
.teambuildvideo .getsocialbox img {
    height: 460px;
    border-radius: 0;
}
.teambuildvideo > .container-fluid {
    padding: 0;
}
.teambuildvideo .getsocialbox::after {
    border-radius: 0;
}
.teambuildhowit, .mixologycontent, .faqsareateam, .morequesitons {
    padding: 15px 80px;
}
.teambuildhowit h2, .mixologycontent h2, .morequesitons h2 {
    margin: 0 0 10px;
    font-size: 26px;
    font-weight: 700;
}
.teambuildhowit ol li, .mixologycontent ul li {
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 8px;
}
.teambuildvideo {
    background-color: var(--color6);
}
.teambuildhowit p, .mixologycontent p {
    margin: 0;
    font-size: 17px;
}
.mixology > .container-fluid {
    padding: 0;
}
.mixologycontent p {
    margin: 0 0 5px;
    color: var(--color2);
}
.mixology {
    background-color: var(--color13);
}
.mixologycontent h2, .mixologycontent ul li {
    color: var(--color2);
}
.mixologycta, .morequestioncts {
    display: flex;
    justify-content: flex-start;
    margin: 15px 0 0;
}
.mixologycta a, .morequestioncts a {
    background-color: var(--color4);
    color: var(--color2);
    display: block;
    padding: 7px 15px 10px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 5px;
    transition: all 0.5s ease;
}
.mixologycta a:hover, .morequestioncts a:hover {
    background-color: var(--color1);
}
.gallery {
    background-color: var(--color7);
}
.gallery > .container-fluid {
    padding: 0;
}
.faqsareateam > h2 {
    font-size: 27px;
    font-weight: 700;
    margin: 0 0 20px;
    color: var(--color2);
}
.faqsareateam .accordion-button:not(.collapsed) {
    color: var(--color2);
    background-color: var(--color4);
}
.faqsareateam .accordion-button:not(.collapsed)::after {
    filter: brightness(0) invert(1);
}
.faqsareateam .accordion-button:focus {
    box-shadow: none
}
.faqsareateam .accordion-body p {
    margin: 0;
}
.morefaqs {
    background-color: var(--color10);
}
.morefaqs > .container-fluid {
    padding: 0;
}
.morquesitonimg img {
    height: 500px;
    object-fit: cover;
    object-position: top;
}