.ourfounder {
    background-color: var(--color9);
}
.foundercotnet {
    padding: 45px 110px;
}
.foundercotnet h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 15px;
    line-height: 1.5;
}
.foundercotnet p {
    margin: 0 0 15px;
    color: var(--color1);
}
.foundercotnet .founderbtns {
    margin: 35px 0 0;
}
.foundercotnet .founderbtns a {
    background-color: var(--color1);
    color: var(--color2);
    transition: all 0.5s ease;
    padding: 12px 45px 15px;
    font-size: 18px;
}
.foundercotnet .founderbtns a:hover {
    color: var(--color2);
    background-color: var(--color4);
}
@media screen and (max-width: 1366px) {
    .ourfounder .founderimage img {
        height: 464px;
        object-fit: cover;
    }
    .foundercotnet h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 1100px) {
    .foundercotnet {
        padding: 25px 30px;
    }
    .foundercotnet h2 {
        font-size: 30px;
    }
    .ourfounder .founderimage img {
        height: 418px;
    }
}
@media screen and (max-width: 800px) {
    .foundercotnet h2 {
        font-size: 25px;
    }
    .foundercotnet p {
        font-size: 14px;
    }
    .foundercotnet .founderbtns {
        margin: 23px 0 0;
    }    
    .foundercotnet .founderbtns a {
        padding: 8px 22px 11px;
        font-size: 16px;
    }
    .ourfounder .founderimage img {
        height: 559px;
    }
}
@media screen and (max-width: 767px) {
    .ourfounder .founderimage img {
        height: auto;
    }
    .ourfounder {
        padding: 15px;
    }
    .foundercotnet {
        padding: 8px 0px 18px;
    }
}