.momencecode {
    background-color: #fbfbfb;
    padding: 50px 0;
}
.faqs {
    padding: 50px 0 80px;
}
.faqs h3 {
    text-align: center;
    margin: 0 0 30px;
}
.faqs .accordion-button:not(.collapsed) {
    color: var(--color2);
    background-color: var(--color4);
}
.faqs .accordion-body p {
    margin: 0;
}
.faqs .accordion-button:focus {
    box-shadow: none
}
.faqs .accordion-button:not(.collapsed)::after {
    filter: brightness(0) invert(1);
}