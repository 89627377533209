.studiomap {
    filter: grayscale(1);
    transition: all 0.5s ease;
    height: 450px;
    overflow: hidden;
}
.locations > .container-fluid {
    padding: 0;
}
.studiomap:hover {
    filter: grayscale(0);
}
.locations {
    background-color: var(--color9);
}
.studioaddress {
    padding: 35px 110px;
}
.mainaddresshead h2 {
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 12px;
    line-height: 1.5;
}
.mainaddresshead h3 {
    margin: 0;
    font-size: 23px;
    font-weight: 600;
}
.mainaddresshead {
    margin: 0 0 15px;
}
.addresslocation p {
    margin: 0 0 11px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    display: flex;
    align-items: center;
    column-gap: 12px;
}
.addressbtns {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 30px 0 0;
}
.addressbtns a {
    display: block;
    font-size: 18px;
    background-color: var(--color4);
    color: var(--color2);
    padding: 7px 20px 10px;
    border-radius: 5px;
    transition: all 0.5s ease;
    font-weight: 600;
    text-transform: uppercase;
}
.addressbtns a:hover {
    background-color: var(--color1);
}
.themecolor {
    background-color: var(--color4);
}
.themecolor h2, .themecolor h3, .themecolor p {
    color: var(--color2);
}
.themecolor .addressbtns a {
    background-color: var(--color1);
}
.themecolor .addressbtns a:hover {
    background-color: var(--color2);
    color: var(--color4);
}
.yellowcolor {
    background-color: var(--color5);
}