.thankyou {
    padding: 50px 0;
    background-position: center;
    background-size: cover;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
}
.thankyoumeesage {
    border: 2px dashed var(--color2);
    padding: 15px;
    text-align: center;
}
.thankyoumeesage h2 {
    color: var(--color2);
    margin: 0;
    font-weight: 900;
    font-size: 54px;
}
.thankyoumeesage p {
    margin: 0;
    font-size: 18px;
    color: var(--color2);
    line-height: 1.5;
    font-weight: 600;
}
.backtohomebtnsth a {
    background-color: var(--color2);
    font-weight: 600;
    color: var(--color1);
    text-transform: uppercase;
    padding: 10px 20px 11px;
    border-radius: 3px;
}
.backtohomebtnsth {
    margin: 30px 0 20px;
}