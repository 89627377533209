.breadcrumbarea {
    background-image: url(../../assets/images/Our-story-header.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 50px 0;
    position: relative;
}
.breadcrumbarea::before {
    content: '';
    background-color: rgb(0 0 0 / 19%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.breadcrumhead {
    position: relative;
}
.breadcrumhead h1 {
    color: var(--color2);
}
.ourstorycotnent {
    padding: 35px 110px;
}
.ourstory {
    background-color: var(--color10);
    margin: 0 0 50px;
}
.ourstorycotnent h2 {
    font-size: 40px;
    margin: 0 0 15px;
    color: var(--color1);
}
.ourstorycotnent p {
    margin: 0 0 15px;
    color: var(--color1);
    font-size: 16px;
}
.ourstorycotnent p a {
    color: var(--color4);
    transition: all 0.5s ease;
}
.ourstorycotnent p a:hover {
    color: var(--color1);
}
.ourstoryimage img {
    height: 490px;
}
.getsocialhead {
    text-align: center;
    margin: 0 0 20px;
}
.getsocial {
    padding: 0 0 80px;
}
.getsocialbox img {
    height: 234px;
    object-fit: cover;
    border-radius: 5px;
}
.getsocialbox {
    margin: 20px 0 0;
    overflow: hidden;
    position: relative;
}
.getsocialbox::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 39%);
    border-radius: 5px;
}
.videobtns {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -37%);
    cursor: pointer;
    z-index: 9;
}
.videobtns svg {
    height: 50px;
    width: 50px;
}
.video-popup {
    max-width: 60%;
}
.video-popup .modal-body {
    padding: 0;
    position: relative;
    height: 500px;
}
.videopopupclose {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}
.videopopupclose button {    
    background-color: var(--color5);
    opacity: 1;
    border-radius: 0;
    padding: 15px;
    transition: all 0.5s ease;
}
.video-popup .modal-content {
    overflow: hidden;
}
.videopopupclose button:hover {
    background-color: var(--color2);
    opacity: 1;
}
.breadcrumhead p {
    color: var(--color2);
    font-size: 17px;
    margin: 0;
    font-weight: bold;
}
@media screen and (max-width:1515px){
    .ourstoryimage img {
        height: 561px;
    }
}
@media screen and (max-width:1400px){
    .ourstoryimage img {
        height: 461px;
    }
    .ourstorycotnent {
        padding: 25px 30px;
    }
    .ourstorycotnent h2 {
        font-size: 30px;
    }
}
@media screen and (max-width:1100px){
    .ourstoryimage img {
        height: 581px;
    }
    .video-popup .modal-body {
        height: 400px;
    }
    .video-popup .modal-body iframe {
        height: 400px;
    }
}
@media screen and (max-width: 800px) {
    .ourstorycotnent h2 {
        font-size: 25px;
    }
    .ourstorycotnent p {
        font-size: 14px;
    }
    .getsocialbox img {
        height: 124px;
    }
    .videobtns svg {
        height: 30px;
        width: 30px;
    }
    .video-popup {
        max-width: 91%;
    }
}
@media screen and (max-width: 600px) {
    .ourstory > .row > .col-lg-6:last-child {
        order: -1 !important;
    }
    .ourstoryimage img {
        height: auto;
    }
    .ourstory {
        padding: 15px;
    }
    .ourstorycotnent {
        padding: 8px 0px 18px;
    }
    .getsocialbox img {
        height: 226px;
    }
    .videobtns svg {
        height: 40px;
        width: 40px;
    }
    .getsocial {
        padding: 0 0 60px;
    }
    .video-popup {
        max-width: 96%;
    }
    .video-popup .modal-body {
        height: 300px;
    }
    .video-popup .modal-body iframe {
        height: 300px;
    }
}