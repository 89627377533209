.pricingarea {
    padding: 50px 0;
}
.pricinghead h2 {
    text-align: center;
    font-weight: 600;
}
.pricingbox {
    background-color: var(--color6);
    padding: 15px;
    border-radius: 5px;
    min-height: 282px;
}
.pricingbox h2 {
    color: var(--color1);
    font-size: 21px;
    font-weight: 600;
    text-align: center;
}
.pricearea {
    display: flex;
    align-items: baseline;
    column-gap: 10px;
    justify-content: center;
}
.pricearea h3 {
    font-size: 40px;
}
.pricearea p {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
}
.buypackagebtn {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}
.buypackagebtn a {
    background-color: var(--color5);
    padding: 8px 23px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--color1);
    line-height: 1.5;
    border-radius: 5px;
}
.benifitboxs ul li {
    font-weight: 600;
    font-size: 16px;
}
.popularprice {
    background-color: var(--color4);
}
.popularprice h2, .popularprice h3, .popularprice p, .popularprice ul li {
    color: var(--color2);
}
.pricinghead {
    margin: 0 0 42px;
}
.semiprice {
    background-color: var(--color5);
}
.pricebottomcotnent {
    text-align: center;
}
.pricebottomcotnent h4 {
    line-height: 1.5;
    font-weight: 600;
}
.benifitboxs ul {
    list-style: none;
    text-align: center;
    margin: 0;
}