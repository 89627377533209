.faqsarea > .container-fluid {
    padding: 0;
}
.faqsdata {
    padding: 24px 110px 60px;
    position: relative;
}
.faqsdata h3 {
    font-size: 40px;
    margin: 0 0 15px;
    color: var(--color1);
}
.faqsdata .accordion-button:not(.collapsed) {
    color: var(--color2);
    background-color: var(--color4);
}
.faqsdata .accordion-button:not(.collapsed)::after {
    filter: brightness(0) invert(1);
}
.faqsdata .accordion-button {
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.5;
}
.faqsdata .accordion-button:focus {
    box-shadow: none
}
.faqsdata .accordion-body p {
    margin: 0;
}
.faqsarea {
    background-image: url(../../assets/images/slider/slider6.webp);
    background-position: top center;
    background-size: cover;
    position: relative;
}
.faqsarea::before {
    content: '';
    background-color: var(--color5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.7;
}
.corportate {
    background-image: url(../../assets/images/slider/slider1.webp);
}
.corportate::before {
    background-color: var(--color4);
}
.corportate .faqsdata h3, .virtual .faqsdata h3 {
    color: var(--color2);
}
.private {
    background-image: url(../../assets/images/slider/slider5.webp);
}
.private::before {
    background-color: var(--color6);
}
.virtual {
    background-image: url(../../assets/images/slider/slider7.webp);
}
.virtual::before {
    background-color: var(--color8);
}
@media screen and (max-width: 1100px) {
    .faqsdata {
        padding: 24px 15px 60px;
    }
    .faqsdata h3 {
        font-size: 30px;
    }
}
@media screen and (max-width: 600px) {
    .faqsdata h3 {
        font-size: 25px;
    }
    .breadcrumhead p {
        font-size: 14px;
        line-height: 1.5;
    }
}