.sliderarea .slick-list .slick-slide img {
  height: 700px;
  object-fit: cover;
  object-position: top;
}
.slick-track {
  overflow: hidden;
}
@media screen and (max-width: 1100px) {
  .sliderarea .slick-list .slick-slide img {
    height: 490px;
  }
}
@media screen and (max-width:800px) {
  .sliderarea .slick-list .slick-slide img {
      height: 400px;
  }
}
@media screen and (max-width:767px) {
  .sliderarea .slick-list .slick-slide img {
      height: 285px;
  }
}