.blogarea > .container-fluid {
    padding: 0;
}
.blogarea {
    background-color: var(--color9);
}
.bloggirdcontent {
    padding: 35px 110px;
}
.blogarea .bloggridimg img {
    height: 450px;
    object-fit: cover;
}
.blogridheaidng h2 {
    margin: 0;
    font-size: 30px;
    line-height: 1.5;
    font-weight: 600;
}
.blogridheaidng h2 a {
    color: var(--color1);
    transition: all 0.5s ease;
}
.blogridheaidng h2 a:hover {
    color: var(--color4);
}
.blogridmeta {
    display: flex;
    column-gap: 15px;
    align-items: center;
    margin: 15px 0;
}
.blogridmeta p {
    margin: 0;
    display: flex;
    align-items: center;
}
.blogauthor span, .blogdate span {
    margin: 0 0 0 5px;
}
.blogridreadmore a {
    background-color: var(--color4);
    color: var(--color2);
    padding: 7px 15px 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 1px;
    transition: all 0.5s ease;
}
.blogridreadmore a:hover {
    background-color: var(--color1);
}
@media screen and (max-width: 1515px) {
    .bloggirdcontent {
        padding: 35px 60px;
    }
}
@media screen and (max-width: 1100px) {
    .bloggirdcontent {
        padding: 30px 30px;
    }
    .blogridheaidng h2 {
        font-size: 25px;
    }
}
@media screen and (max-width: 800px) {
    .blogridmeta {
        margin: 7px 0;
    }
    .blogridheaidng h2 {
        font-size: 18px;
    }
    .blogridshordis p {
        font-size: 14px;
    }
    .blogridmeta p {
        font-size: 14px;
    }
    .blogarea .bloggridimg img {
        height: 400px;
    }
    .bloggirdcontent {
        padding: 18px 15px;
    }
    .blogridreadmore a {
        padding: 5px 10px 6px;
        font-size: 15px;
    }
}
@media screen and (max-width: 800px) {
    .blogarea > .container-fluid .row:nth-child(even) .col-lg-6:last-child {
        order: -1 !important;
    }
    .bloggridimg {
        margin: 20px 0 0;
    }
    .blogarea {
        padding: 15px;
    }
    .bloggirdcontent {
        padding: 18px 0px;
    }
    .blogarea .bloggridimg img {
        height: auto;
        max-height: 300px;
    }
}