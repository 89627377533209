.welness {
    padding: 50px 0;
}
.wellnewsshead h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 8px;
}
.wellnewsshead {
    text-align: center;
}
.wellnewsshead p {
    font-size: 17px;
    margin: 0;
}
.productivity .retreatimg img {
    height: 500px;
    object-fit: cover;
}
.workshop .retreatimg img {
    height: 1000px;
    object-fit: cover;
}