.manuarea .topbararea {
    background-color: var(--color4);
    padding: 15px 0;
}
.manuarea .topbar p {
    color: var(--color2);
    margin: 0;
}
.manuarea .topbar p a {
    color: var(--color2);
    transition: all 0.5s ease;
}
.manuarea .topbar p a:hover {
    color: var(--color1);
}
.manuarea .topbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
}
.manuarea .topbar ul li {
    display: inline-block;
    margin: 0 0 0 25px;
    position: relative;
}
.manuarea .topbar ul li a {
    margin: 0;
    color: var(--color2);
    transition: all 0.5s ease;
}
.manuarea .topbar ul li a:hover {
    color: var(--color1);
}
.manuarea .topbar ul li:first-child {
    margin: 0;
}
.manuarea .topbar ul li a i {
    margin: 0 5px 0 0px;
}
.manuarea .topbar ul li::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 3px;
    height: 22px;
    width: 2px;
    background-color: var(--color2);
}
.manuarea .topbar ul li:last-child:after {
    display: none;
}
.logoarea a img {
    width: 100px;
    height: auto;
    object-fit: contain;
    padding: 5px 0;
}
.manuareas {
    padding: 0px 0;
}
.mainmenu > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.mainmenu ul li {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}
.mainmenu > ul > li > a {
    color: var(--color1);
    display: block;
    padding: 42px 15px;
    transition: all 0.5s ease;
}
.mainmenu > ul > li > a:hover {
    color: var(--color4);
}
.mainmenu > ul > li.has-menu {
    position: relative;
}
.mainmenu > ul > li.has-menu > ul.sub-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 200px;
    background-color: var(--color2);
    top: 109px;
    left: 0;
    transition: all 0.5s ease;
    display: none;
    z-index: 9;
}
.mainmenu > ul li.has-menu:hover ul.sub-menu {
    display: block;
}
.mainmenu > ul > li.has-menu > ul.sub-menu li a {
    color: var(--color1);
    display: block;
    padding: 5px 10px;
    transition: all 0.5s ease;
    border-bottom: 1px solid #f3f3f3;
}
.mainmenu > ul > li.has-menu > ul.sub-menu li:last-child a {
    padding: 0px 10px 10px;
}
.mainmenu ul li.has-menu > ul.sub-menu li a:hover {
    color: var(--color4);
}
.mainmenu > ul > li.has-menu::before {
    content: '\f107';
    position: absolute;
    right: -8px;
    top: 44px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    transition: all 0.5s ease;
}
.mainmenu > ul > li.has-menu:hover::before {
    transform: rotate(180deg);
}
.manuareas.sticky {
    position: fixed;
    /* background-color: var(--color2); */
    background-color: rgb(255 255 255 / 80%);
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    box-shadow: 0px 0px 15px 0px #e8e8e8;
    backdrop-filter: blur(10px);
}
.logoarea {
    width: 29% !important;
}
.otherheadernew {
    width: 71% !important;
}
.otherheadernew h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 26px;
    letter-spacing: 2px;
    margin: 0;
    font-family: var(--font1) !important;
    color: var(--color1);
}
.otherheadernew h2 span {
    background-color: var(--color1);
    color: var(--color2);
    padding: 0 10px 4px;
}
.otherheadernew p {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin: 1px 0 0;
    letter-spacing: 4.7px;    
    color: var(--color1);
}
.mobilemenu {
    display: none;
}
.mobilemenu .mobiletoggle {
    background-color: var(--color4);
    border: none;
    padding: 11px 15px;
    line-height: 0;
    font-size: 27px;
    color: var(--color2);
}
.menuleftslide.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(0 0 0 / 60%);
    z-index: 99;
    /* visibility: hidden;
    opacity: 0; */
    left: -100%;
    -webkit-transition: all ease 1s;
    transition: all ease 1s;
    width: 0;
}
.menuleftslide.sidebar.closed {
    /* visibility: hidden;
    opacity: 0; */
    left: -100%;
    width: 0%;
}
.menuleftslide.sidebar.open {
    left: 0%;
    width: 100%;
}
.mobilemenuarea {
    background-color: var(--color2);
    height: 100%;
    position: relative;
    width: 100%;
    max-width: 310px;
    border-right: 3px solid var(--color4);
    left: -100%;
    opacity: 0;
    visibility: hidden;    
    -webkit-transition: all ease 1s;
    transition: all ease 1s;
}
.menuleftslide.sidebar.open .mobilemenuarea {
    left: 0;
    opacity: 1;
    visibility: visible;
}
.mobilemenuarea .close_menu {
    border: none;
    position: absolute;
    right: -16.5px;
    top: 25px;
    padding: 0;
    width: 33px;
    height: 33px;
    line-height: 9px;
    font-size: 18px;
    z-index: 1;
    color: var(--color2);
    background-color: var(--color4);
    border-radius: 50%;
}
.mobilemenulogoarea {
    background-color: var(--color9);
    text-align: center;
    padding: 40px 0;
}
.mobilemenulogoarea img {
    max-width: 110px;
    height: auto;
}
.mobilemenuitems {  
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
    padding-bottom: 40px;
    margin-top: 33px;
    text-align: left;
}
.mobilemenuitems ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.mobilemenuitems>ul {
    padding: 0 40px;
}
.mobilemenuitems ul li {
    border-bottom: 1px solid #fdedf1;
    list-style-type: none;
}
.mobilemenuitems ul li a {
    display: block;
    position: relative;
    line-height: 1.4;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--title-color);
    padding: 12px 0 12px 18px;
}
.mobilemenuitems ul li a:before {
    content: '\f105';    
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    transition: all 0.5s ease;
    position: absolute;
    left: 0;
    top: 12px;
    margin-right: 10px;
    display: inline-block;
}
.mobilemenuitems ul li ul li {
    padding-left: 20px;
}
.mobilemenuitems ul .has-submenu>a .th-mean-expand {
    position: absolute;
    right: 0;
    top: 50%;
    font-weight: 400;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-top: -12.5px;
    display: inline-block;
    text-align: center;
    background-color: #F5F5F5;
    color: #161921;
    box-shadow: 0 0 20px -8px #ad885880;
    border-radius: 50%;
}
.mobilemenuitems ul li a {
    display: block;
    position: relative;
    line-height: 1.4;
    font-size: 16px;
    text-transform: capitalize;
    color: #161921;
    padding: 12px 0 12px 18px;
}
.mobilemenuitems ul .has-submenu>a .th-mean-expand:before {
    content: "\2b";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
}
.mobilemenuitems ul .has-submenu.active>a .th-mean-expand:before {
    content: "\f068";
}
.mobilemenuitems ul li.active>a {
    color: var(--color4);
}
@media screen and (max-width: 1366px) {
    .mainmenu ul li {
        font-size: 15px;
    }
    .mainmenu > ul > li > a {
        padding: 42px 13px;
    }    
    .mainmenu > ul > li.has-menu::before {
        top: 41px;
    }
    .otherheadernew h2 {
        font-size: 23px;
    }
    .otherheadernew p {
        font-size: 17px;
    }
}
@media screen and (max-width: 1024px) {
    .manuarea .topbar p {
        font-size: 14px;
    }
    .manuarea .topbar ul li {
        font-size: 14px;
    }
    .manuarea .topbar ul li::after {
        right: -12px;
        height: 17px;
    }
    .mainmenu ul li {
        font-size: 13px;
    }
    .mainmenu > ul > li > a {
        padding: 42px 8px;
    }
    .mainmenu > ul > li.has-menu::before {
        top: 42px;
    }
    .otherheadernew h2 {
        font-size: 18px;
    }
    .otherheadernew p {
        font-size: 13px;
    }
    .logoarea a img {
        width: 75px;
    }    
}
@media screen and (max-width:800px) {
    .desktopmenu {
        display: none;
    }
    .mobilemenu {
        display: block;
        text-align: right;
    }
    .manuarea .topbar p {
        text-align: center;
    }
    .manuarea .topbar ul {
        text-align: center;
    }
    .manuarea .topbararea {
        padding: 6px 0;
    }   
    .logoarea a img {
        width: 70px;
    } 
    .logoarea {
        width: 18% !important;
    }
    .otherheadernew h2 {
        font-size: 19px;
    }
    .otherheadernew p {
        font-size: 14px;
    }
}
@media screen and (max-width:767px) {
    .logoarea {
        width: 30% !important;
    }
    .otherheadernew {
        width: 70% !important;
    }
    .manuarea .topbar ul li:first-child, .manuarea .topbar p, .manuarea .topbar ul li a i {
        font-size: 14px;
    }
}
@media screen and (max-width: 420px) {
    .manuarea .topbar ul li:first-child, .manuarea .topbar p, .manuarea .topbar ul li a i {
        font-size: 13px;
    }
    .otherheadernew h2 {
        font-size: 15px;
    }
    .otherheadernew p {
        font-size: 10px;
        margin: 6px 0 0;
    }
}