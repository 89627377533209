.contactusheaing h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 5px;
}
.contactusheaing p {
    line-height: 1.5;
    margin: 0 0 10px;
}
.contactusarea {
    padding: 50px 0;
    background-color: var(--color9);
}
.contactinfos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contactinfobox {
    padding: 15px;
    text-align: center;
    background-color: var(--color2);
    border-radius: 5px;
    width: 45%;
    margin: 15px 0 0;
}
.contactinfobox p {
    margin: 0;
}
.contactinfoicon {
    font-size: 25px;
    margin: 0 0 10px;
}
.contactinfodata p {
    font-size: 18px;
    font-weight: 600;
}
.contactinfodata p a {
    color: var(--color1);
    transition: all 0.5s ease;
}
.contactinfodata p a:hover {
    color: var(--color4);
}
.checkboxarea .form-checkbox {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    margin: 0 0 10px;
}
.checkboxarea .form-checkbox label {
    line-height: 1.5;
}
.checkboxarea .form-checkbox input {
    position: relative;
    top: 7px;
}
.contactform .form-group label {
    font-weight: 700;
    margin: 15px 0 4px;
}
.contactform .form-group .form-control {
    border-radius: 0;
    padding: 10px 15px;
}
.contactform .checkboxarea p {
    margin: 15px 0 7px;
    font-weight: 600;
    color: var(--color1);
}
.contactform .checkboxarea label {
    font-weight: 500;
    color: var(--color1);
}
.contactform .formbtns p {
    margin: 5px 0 10px;
    font-weight: 600;
}
.contactform .formbtns button {
    background-color: var(--color4);
    border: none;
    font-weight: 600;
    color: var(--color2);
    font-size: 20px;
    text-transform: uppercase;
    padding: 6px 30px 8px;
    transition: all 0.5s ease;
}
.contactform .formbtns button:hover {
    background-color: var(--color1);
}
.contactform .form-group .form-control:focus {
    box-shadow: none;
    border-color: var(--color4);
}
.formloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(231 229 236 / 74%);
}
.contactform {
    position: relative;
}
.formloader .spinner {
    border: 6px solid var(--color2);
    border-top: 6px solid var(--color4);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.contactform .form-group p.error {
    color: var(--color4);
    font-size: 15px;
}
.contactform .form-group label span.required {
    color: var(--color4);
}