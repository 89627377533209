.zumba_section{
    background-color: #e9f0ec;
    padding:40px 0px;
}
.post-list .widget-title{
    display: block;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
    width: auto;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;
    padding-left: 30px;
    padding-bottom: 0;
    text-transform: capitalize;
    color: var(--color1) !important;
    font-family: var(--font1) !important;
    font-weight:700;
}
.post-list .widget-title::before {
    content: '\f621';
    font-family: "Font Awesome 6 Free";
    position: absolute;
    left: 0;
    font-size: 12px;
    color: var(--color4);
    top: 50%;
    transform: translate(0, -50%);
}
.zumba_section .class_services .widget{
    background-color: var(--color2);
    padding-top: 40px;
    border-radius: 30px;
    border: none;
    padding: 25px;
    margin-bottom: 55px;
}
.class_services{
    position: sticky;
    top:130px;
}
.zumba_section .class_services .widget  ul{
    padding:0px;
    margin:0px;
    list-style: none;
}
.zumba_section .class_services .widget  ul li {
    margin: 0;
    position: relative;
    line-height: 26px;
    border-bottom: 1px solid rgba(24, 26, 23, .1);
}
.zumba_section .class_services .widget  ul li:last-child{
    border-bottom: 0px solid rgba(24, 26, 23, .1);
}
.zumba_section .class_services .widget  ul li a{
  padding: 16px 60px 16px 25px;
  display: block;
  position: relative;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 700;
  font-family: var(--font1);
  color:var(--color1);
  text-transform: uppercase;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.zumba_section .class_services .widget  ul li.active a{
    transform: translateX(1%);
    color:var(--color4);
}
.zumba_section .class_services .widget  ul li:hover a{
    transform: translateX(1%);
    color:var(--color4);
}
.zumba_section .class_services .widget  ul li a:after {
    position: absolute;
    content: "";
    left: 0;
    font-size: 20px;
    top: 50%;
    width: 7px;
    height: 7px;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: var(--color4);
    border-radius: 50%;
    transition: all 0.4s ease;
}
.zumba_section .class_services .widget  ul li a:before {
    position: absolute;
    content: '\f621';
    font-family: "Font Awesome 6 Free";
    left: inherit;
    right: 8px;
    font-size: 10px;
    color: inherit;
    top: 50%;
    z-index: 1;
    color:var(--color1);
    background-color: transparent;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: initial;
    transition: all 0.4s ease-in;
}
.zumba_section .zumba_img img{
    border-radius: 30px;
    object-fit: cover;
    height: 300px;
    width: 100%;
    margin-bottom:30px;
}
.dance_content h3{
    font-family: var(--font1) !important;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 15px;
    position: relative;
}
.dance_content p{
    font-family: var(--font1) !important;
    font-weight: 500;
    line-height: 27px;
    color: var(--bs-black);
    font-size: 16px;
}
.dance_content .quotes {
    background-color: var(--color10);
    padding: 30px 30px 20px 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    border-left: 5px solid var(--bs-black);
}
.dance_content .quotes strong {
    font-weight: 700;
    font-size: 22px;
    color: var(--color1);
    font-family: var(--font2);
    margin-bottom:10px;
    display: block;
    font-style: italic;
}
.dance_content .quotes p{
    margin-bottom:0px;
    font-size:15px;
}
.dance_content ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.dance_content ul>li {
    margin-bottom: 20px;
    padding-left: 40px;
    font-family: var(--font1);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    position: relative;
    counter-increment: step-counter;
}
.dance_content ul>li::before {
    content: counter(step-counter);
    left: 0;
    position: absolute;
    top: 5px;
    background-color: var(--color4);
    /* font-weight: bold; */
    /* padding: 0px 8px; */
    border-radius:5px 50px 50px 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 17px;
    color: var(--color2);
    line-height: 25px;
    transition: 0.5s;
}
.dance_content ul>li:hover::before {
    border-radius: 50px 5px 50px 50px;
    background-color: var(--color1);
}
.textwidget{
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 25px;
    background-size: cover;
    background-color: var(--color4);
    border-radius: 30px;
    background-size: auto;
    background-image: url('../../../assets/images/pattern.png');
}
.textwidget h4{
    font-size: 34px;
    color: var(--color2);
    font-family: var(--font1) !important;
    font-weight: 700;
    line-height: 35px;
}
.textwidget .phone_icon i{ 
    color: var(--color2);
    font-size: 17px;
    padding-right: 5px;
}
.textwidget .phone_icon a {
    font-size: 16px;
    line-height: 28px;
    color: var(--color2);
    text-decoration: underline !important;
}
.textwidget .booking_btn a{
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-style: normal;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    transition: all .3s;
    background-color: #181a17;
    display: block;
    font-family: var(--font1);
    font-weight: 600;
    color: var(--color2);
}
.textwidget .booking_btn a:hover{
    background-color: var(--color2);
    color: var(--color1);
}
.zumba_bg{
    background-color:#ffece9;
} 
.zumba_bg2{
    background-color: #d3edec;
}
.zumba_bg p{
    font-family: var(--font1) !important;
    font-weight: 500 !important;
    line-height: 27px;
    font-size: 16px !important;
}
.zumba_bg .retreatimg img {
    height: 620px;
}
.zumba_bg > .container-fluid {
    padding: 0;
}
.bollwybood .retreatimg img {
    height: 754px;
}