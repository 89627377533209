.mainfooter {
    background-color: var(--color8);
    padding: 30px 15px;
}
.footerbox a {
    display: block;
    margin: 0 0 15px;
}
.footerbox a img {
    object-fit: contain;
}
.footerbox p {
    color: var(--color2);
}
.footerbox .footerheading h2 {
    color: var(--color2);
    margin: 0;
    font-size: 20px;
    position: relative;
    border-bottom: 1px solid var(--color2);
    padding: 0 0 10px;
    text-transform: capitalize;
}
.footerbox .footerdata ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footerbox .footerheading {
    margin: 0 0 15px;
}
.footerbox .footerheading h2::after {
    content: '';
    background-color: var(--color4);
    height: 3px;
    width: 60px;
    position: absolute;
    left: 0;
    bottom: -2px;
}
.footerbox .footerdata ul li a {
    margin: 0;
    color: var(--color2);
    transition: all 0.5s ease;
    display: block;
}
.footerbox .footerdata ul li {
    margin: 0 0 10px;
}
.footerbox .footerdata ul li a:hover {
    color: var(--color4);
    padding-left: 10px;
}
.footerbottom {
    border-top: 1px solid #616161;
    margin: 15px 0 0;
    padding: 20px 0 0;
}
.footerbox .contactfooter ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footerbox .contactfooter ul li {
    display: flex;
    align-items: center;
    color: var(--color2);
    margin: 0 0 10px;
}
.footerbox .contactfooter ul li a {
    margin: 0 0 0px 15px;
    color: var(--color2);
    transition: all 0.5s ease;
}
.footerbox .contactfooter ul li a:hover {
    color: var(--color4);
}
.footercopyright {
    background-color: var(--color4);
    padding: 10px 0;
}
.footercopyright .copyrightext p {
    margin: 0;
    color: var(--color2);
    text-align: center;
}
.footercopyright .copyrightext p a {
    color: var(--color2);
    transition: all 0.5s ease;
}
.footercopyright .copyrightext p a:hover {
    color: var(--color1);
}
@media screen and (max-width:1100px){
    .footerbox .footerheading h2 {
        font-size: 18px;
    }
}
@media screen and (max-width:800px){
    .footerbox p {
        text-align: center;
    }
}