.testimonials {
    padding: 50px 0 70px;
}
.testimonailheaidng h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 15px;
    line-height: 1.5;
}
.reviewhead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px;
}
.googlereviews svg {
    object-fit: contain;
    height: 78px;
}
.googlereviews span {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 0 10px;
}
.googlereviews {
    display: flex;
    justify-content: left;
    align-items: center;
}
.googleratings {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    margin: -22px 0 0;
}
.totalreveiwcount p {
    margin: 0;
    font-size: 16px;
    line-height: 14px;
    color: rgba(17, 17, 17, 0.5);
}
.howrat {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}
.ratingstars {
    color: #fdbf01;
    font-size: 24px;
    line-height: 1;
}
.testimonailbtns a {
    font-size: 14px;
    background-color: rgb(25, 123, 255);
    color: var(--color2);
    font-weight: 600;
    padding: 15px 23px;
    border-radius: 5px;
    transition: all 0.5s ease;
}
.testimonailbtns a:hover {
    background-color: var(--color4);
}
.reviewslider .slick-slide {
    padding: 0 10px;
}
.reviewslider .slick-list {
    margin: 0;
}
.tesimonialbox {
    background-color: #ececec;
    padding: 15px;
}
.testiboxhead {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
}
.testimonialimg img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 100%;
}
.testimonialname p {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}
.testimonialstarbox {
    color: #febe01;
    margin: 7px 0;
}
.testimonailcontent p {
    margin: 0;
}
.testimonailcontent {
    height: 73px;
    overflow-x: hidden;
}
.reviewslider .slick-slider {
    height: 230px;
}
.reviewslider .slick-prev:before, .reviewslider .slick-next:before {
    color: var(--color1);
}
@media screen and (max-width: 1366px) {
    .testimonailheaidng h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 1100px) {
    .testimonailcontent {
        height: 95px;
    }
    .testimonialname p {
        font-size: 15px;
        line-height: 1.5;
    }
    .testimonailheaidng h2 {
        font-size: 25px;
    }
}
@media screen and (max-width: 800px) {
    .ratingstars {
        font-size: 19px;
    }
}
@media screen and (max-width: 600px) {
    .testimonials .slick-prev, .testimonials .slick-next {
        display: none !important;
    }
    .googlereviews svg {
        height: 63px;
    }
    .googlereviews span {
        font-size: 17px;
    }
    .howrat {
        font-size: 18px;
    }
    .ratingstars {
        font-size: 16px;
    }
    .totalreveiwcount p {
        font-size: 14px;
    }
    .testimonailbtns a {
        padding: 10px 15px;
    }
    .reviewhead {
        flex-wrap: wrap;
        justify-content: center;
    }
    .googlereviews {
        justify-content: center;
        margin: -21px 0 0;
    }
    .reviewheadright {
        margin: 20px 0 0;
    }
    .testimonailheaidng h2 {
        font-size: 23px;
    }
}