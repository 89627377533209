.newservice {
    margin: -10px 0 0;
    background-color: var(--color4);
    position: relative;
}
.newserviceimgae img {
    height: 500px;
    object-fit: cover;
}
.newservicecontent {
    padding: 35px 110px;
}
.newservicecontent h2 {
    font-size: 40px;
    margin: 0 0 15px;
    color: var(--color2);
}
.newservicecontent p {
    margin: 0 0 15px;
    color: var(--color2);
}
.newservicecontent .newservicebtn {
    margin: 35px 0 0;
}
.newservicecontent .newservicebtn a {
    background-color: var(--color2);
    color: var(--color1);
    transition: all 0.5s ease;
    padding: 12px 45px 15px;
    font-size: 18px;
}
.newservicecontent .newservicebtn a:hover {
    color: var(--color2);
    background-color: var(--color1);
}
.secondservice {
    background-color: var(--color7);
}
.thirdservice {
    background-color: var(--color8);
}
@media screen and (max-width: 1366px) {
    .newservicecontent h2 {
        font-size: 30px;
    }
    .newservicecontent {
        padding: 25px 30px;
    }
    .newserviceimgae img {
        height: 385px;
    }
    .newservicecontent .newservicebtn a {
        padding: 12px 40px 15px;
        font-size: 15px;
    }
}
@media screen and (max-width: 800px) {
    .newservicecontent h2 {
        font-size: 25px;
    }
    .newservicecontent p {
        font-size: 14px;
    }
    .newserviceimgae img {
        height: 370px;
    }
    .newservicecontent .newservicebtn a {
        padding: 8px 22px 11px;
        font-size: 16px;
    }
    .newservicecontent .newservicebtn {
        margin: 20px 0 0;
    }
}
@media screen and (max-width: 767px) {
    .newservice {
        padding: 15px;
    }
    .newservicecontent {
        padding: 8px 0px 18px;
    }
    .secondservice > .row > .col-lg-6:last-child {
        order: -1 !important;
    }
    .newserviceimgae img {
        height: 260px;
    }
}