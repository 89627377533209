.kidsdance > .container-fluid {
    padding: 0;
}
.kidsdance .getsocialbox {
    margin: 0;
}
.kidsdance .getsocialbox img {
    height: 630px;
    border-radius: 0;
}
.kidsdance .getsocialbox::after {
    border-radius: 0;
}
.kidsdance {
    background-color: var(--color9);
    margin: 0 0 70px;
}
.kidescontent {
    padding: 40px 50px;
}
.kidscta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.kidscta a {
    display: block;
    background-color: var(--color4);
    color: var(--color2);
    font-weight: 600;
    font-size: 19px;
    padding: 7px 15px 10px;
    border-radius: 5px;
    transition: all 0.5s ease;
}
.kidscta a:hover {
    background-color: var(--color1);
}